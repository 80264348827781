::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border: 2px solid #F5F5F5;
    background-clip: padding-box;
    border-radius: 10px;
    background: rgba(48,48,48,.58);
}
*:hover::-webkit-scrollbar-thumb {
    border-width: 0;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(51,51,51,.58);
}

* {
    box-sizing: border-box;
    outline-color: transparent;
}

html {
    font-size: 16px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
html,
body {
    width: 100%;
    height: 100%;
    min-width: 320px;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: auto;
    background-color: #fafafa;
    font-family: Arial, Roboto, "Helvetica Neue", sans-serif
}
select,
button,
textarea,
input {
    vertical-align: baseline;
}

textarea {
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    background-color: white;
    flex-direction: column;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(169, 169, 169);
    border-image: initial;
}
textarea:disabled {
    border-color: rgba(48, 48, 48, 0.12) !important;
}
textarea:focus {
    outline: 0;
}

html[dir='rtl'],
html[dir='ltr'],
body[dir='rtl'],
body[dir='ltr'] {
    unicode-bidi: embed;
}

bdo[dir='rtl'] {
    direction: rtl;
    unicode-bidi: bidi-override;
}

bdo[dir='ltr'] {
    direction: ltr;
    unicode-bidi: bidi-override;
}

[dir='ltr'] .flexSpacer {
    margin-right: auto !important;
    box-sizing: border-box;
}

[dir='rtl'] .flexSpacer {
    margin-left: auto !important;
    box-sizing: border-box;
}

.leaflet-container {
    height: 100%;
}

/* $typography */

a {
    text-decoration: none;
    color: inherit;
}

a:focus,
a:hover {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
}

button {
    outline: 0;
}

figure {
    margin: 0;
}

strong,
b {
    font-weight: 700;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
#root .MuiDrawer-root {
    z-index: 1302;
}
#root[aria-hidden="true"] .MuiDrawer-root {
    z-index: 100;
}

.strikethrough {
    text-decoration: line-through;
}

.radius-none {
    border-radius: 0;
}

.radius-round {
    border-radius: 4px;
}

.radius-circle {
    border-radius: 50%;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.block {
    display: block;
}

.button-block {
    display: block;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.center-block {
    display: block;
    margin: 0 auto;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.shadow-none {
    box-shadow: none !important;
}

.border-none {
    border: 0 !important;
}

.background-none {
    background-color: transparent !important;
}

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.ma-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ma-xs {
    margin: 0.55556rem !important;
}

.mt-xs {
    margin-top: 0.55556rem !important;
}

.mr-xs {
    margin-right: 0.55556rem !important;
}

.mb-xs {
    margin-bottom: 0.55556rem !important;
}

.ml-xs {
    margin-left: 0.55556rem !important;
}

.mx-xs {
    margin-right: 0.55556rem !important;
    margin-left: 0.55556rem !important;
}

.my-xs {
    margin-top: 0.55556rem !important;
    margin-bottom: 0.55556rem !important;
}

.ma-1 {
    margin: 1rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.mx-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.ma-2 {
    margin: 1.5rem !important;
}

.mt-2 {
    margin-top: 1.5rem !important;
}

.mr-2 {
    margin-right: 1.5rem !important;
}

.mb-2 {
    margin-bottom: 1.5rem !important;
}

.ml-2 {
    margin-left: 1.5rem !important;
}

.mx-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.my-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.ma-3 {
    margin: 3rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mr-3 {
    margin-right: 3rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mx-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.pa-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.pa-xs {
    padding: 0.55556rem !important;
}

.pt-xs {
    padding-top: 0.55556rem !important;
}

.pr-xs {
    padding-right: 0.55556rem !important;
}

.pb-xs {
    padding-bottom: 0.55556rem !important;
}

.pl-xs {
    padding-left: 0.55556rem !important;
}

.px-xs {
    padding-right: 0.55556rem !important;
    padding-left: 0.55556rem !important;
}

.py-xs {
    padding-top: 0.55556rem !important;
    padding-bottom: 0.55556rem !important;
}

.pa-1 {
    padding: 1rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pl-1 {
    padding-left: 1rem !important;
}

.px-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.pa-2 {
    padding: 1.5rem !important;
}

.pt-2 {
    padding-top: 1.5rem !important;
}

.pr-2 {
    padding-right: 1.5rem !important;
}

.pb-2 {
    padding-bottom: 1.5rem !important;
}

.pl-2 {
    padding-left: 1.5rem !important;
}

.px-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.py-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pa-3 {
    padding: 3rem !important;
}

.pt-3 {
    padding-top: 3rem !important;
}

.pr-3 {
    padding-right: 3rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.px-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-xs-left {
    text-align: left !important;
}

.text-xs-right {
    text-align: right !important;
}

.text-xs-center {
    text-align: center !important;
}

@media (min-width: 600px) {

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 960px) {

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 1280px) {

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1920px) {

    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-xs {
    font-size: 50% !important;
}

.text-sm {
    font-size: 70% !important;
}

.text-md {
    font-size: 80% !important;
}

.text-df {
    font-size: 13px !important;
}

.text-lg {
    font-size: 110% !important;
}

.text-xl {
    font-size: 120% !important;
}

.text-xxl {
    font-size: 60px !important;
}

.font-weight-normal {
    font-weight: normal !important;
}

.font-weight-bold {
    font-weight: 500 !important;
}

.font-italic {
    font-style: italic !important;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table th,
table td {
    white-space: normal !important;
}

table thead tr:first-child th {
    line-height: 1.2 !important;
    border-top: 1px solid;
    border-color: #d8d8d8;
    background-color: rgba(239, 239, 239, 1);
    /*border-left: 1px solid #d8d8d8;*/
}
table thead tr th:first-of-type {
    border-left: 1px solid #d8d8d8;
}

.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
}

@media screen and (max-width: 960px) {

    .table-responsive {
        width: 100%;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive > table {
        margin-bottom: 0;
    }

    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > td,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > thead > tr > th {
        white-space: nowrap;
    }
}


.lg-backdrop,
.lg-outer {
    z-index: 2000;
}

.no-table-data .MuiTable-root {
    table-layout: initial !important;
}

.no-table-data .MuiTable-root {
    table-layout: initial !important;
}

tr {
    height: 48px;
}

.d-inflex {
    display: inline-flex;
}

.blink_me {
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: blinker;
    -moz-animation-duration: 1s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;

    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@-moz-keyframes blinker {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes blinker {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

.filepond--root {
    margin-bottom: 0;
}

.widget-button {
    left: calc((100% - 220px) / 2);
    right: auto !important;
    z-index: 1300 !important;
}
.sender-wrapper {
    position: fixed;
    bottom: 0;
    left: calc((100% - 360px) / 2);
}

.__floater.__floater__open {
    /*filter: drop-shadow(rgba(0,0,0,.0144) 0 0 3px) !important;*/
    /*Фикс замыленного тултипа*/
    filter: unset !important;
    will-change: unset !important;
}
#react-joyride-portal {
    transition: background-color 1000ms linear;
    background-color: rgb(255 255 255 / 8%);
}
#react-joyride-portal:has(div) {
    background-color: transparent;
}
